import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyCEc7Bvw3v2WYoleKGjK69B5rZ2l4LsxJE",
  authDomain: "verivid-76b34.firebaseapp.com",
  projectId: "verivid-76b34",
  storageBucket: "verivid-76b34.appspot.com",
  messagingSenderId: "802415809698",
  appId: "1:802415809698:web:1044147ebd887fde77b3fb",
  measurementId: "G-DYT9Q7DFG8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);
export default app;
