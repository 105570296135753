import ResponsiveAppBar from '../../components/ResponsiveAppBar.js';
import ClassCard from '../../components/ClassCard.js';
import AddClassCard from '../../components/AddClassCard.js';
import { auth } from '../../firebase';

import React, { useState, useEffect } from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import log from 'loglevel';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Classes() {
  const [isPaid, setIsPaid] = useState(false);
  const [numberOfClasses, setNumberOfClasses] = useState(0);

  const [classes, setClasses] = useState([]);
  const [adminClasses, setAdminClasses] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    auth.authStateReady().then(() => {
      const user = auth.currentUser;
      if (user !== null) {
		fetch(process.env.REACT_APP_API_PATH + "/api/class", {
		method: 'GET',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'username': user.email,
			'userid': user.accessToken,
		}
		    }).then((res) => {
		    	if (res.status !== 200) {
		    		log.error("Status was", res.status, "Error")
		    	} else {
			    	res.json().then((res) => {
				    	log.debug("got classes", res, "from server")
				    	setAdminClasses(res['adminClasses'])
				    	setClasses(res['classes'])
				    })
		    	}
		    }).catch(rejected => {
					log.error(rejected);
				});


				fetch(process.env.REACT_APP_API_PATH + "/api/users/" + user.email, {
		        method: 'GET',
		        headers: {
		            'Accept': 'application/json',
		            'Content-Type': 'application/json',
		            'username': user.email,
		            'userid': user.accessToken,
		        }
		    }).then((res) => {
		    	if (res.status !== 200) {
		    		log.error("Status was", res.status, "Error")
		    	} else {
			    	res.json().then((res) => {
				    	log.debug("got user data", res, "from server")
				    	setNumberOfClasses(res["NumOfClasses"])
				    	if (res["PaymentToken"] !== null) {
				    		setIsPaid(true);
				    	}
				    })
		    	}
		    }).catch(rejected => {
					log.error(rejected);
				});
		    
      } else {
      }
    })
  }, [])

	return (
		<>
			<ResponsiveAppBar/>

			<div className="header-container">
			  <h1 className="header-title">{t("classes.title")}</h1>
			</div>
		    <Box sx={{ m: "5%", flexGrow: 1 }}>
		      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={2} sm={4} md={4} key={0}>
        	  	<AddClassCard number_of_classes={numberOfClasses} isPaid={isPaid}/>
            </Grid>
		        {Array.from(adminClasses).map((class_obj, index) => (
		          <Grid item xs={2} sm={4} md={4} key={index}>
		            <ClassCard class_id={class_obj.ID} name={class_obj.Name} description={class_obj.Description} is_admin={true}/>
		          </Grid>
		        ))}
		      </Grid>
		      <br/>
		      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
		        {Array.from(classes).map((class_obj, class_index) => (
		          <Grid item xs={2} sm={4} md={4} key={class_index}>
		            <ClassCard class_id={class_obj.ID} name={class_obj.Name} description={class_obj.Description} is_admin={false}/>
		          </Grid>
		        ))}
		      </Grid>
		    </Box>
		</>
		)
}
