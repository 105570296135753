import * as React from 'react';
import { Box, Chip, Fade, styled, Typography } from '@mui/material';
import { ReactPlayerProps } from 'react-player';
import useWindowSize from '../useWindowSize.js'



const PlayerOverlay = (props) => {
  const { state, title, subtitle, description} = props;
  const size = useWindowSize();    


  const StyledPlayerOverlay = styled('div')`
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: end;
    left: 0;
    top: 0;
    bottom: ${({ state }) => (state.light ? '0' : (size.width > 650 ? '94px' : (size.width > 600 ? '86px' : '76px')))};
    background-color: ${({ state }) => (state.light || state.playing ? 'transparent' : 'rgba(0, 0, 0, 0.4)')};
    opacity: ${({ state }) => (state.playing ? '0' : '1')};
    transition: opacity 0.2s ease-in-out;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .video-player__overlay-inner {
      padding-left: ${({ state }) => (state.light ? '50px' : '25px')};
      padding-bottom: ${({ state }) => (state.light ? '50px' : (size.width > 900 ? '38px' :'10px'))};
      width: ${({ state }) => (state.light ? 'auto' : '100%')};
    }
  `;

  return (
    <StyledPlayerOverlay state={state}>
      <Box className={'video-player__overlay-inner'}>
        <Fade in>
          <Chip label={subtitle} color={'primary'} />
        </Fade>
        <Fade in>
          <Typography variant="h5" color={'white'} mt={size.width > 900 ? 2 : 0}>
            {title}
          </Typography>
        </Fade>
        {size.width > 900 &&
          <Fade in>
            <Typography variant="overline" color={'white'}>
              {description}
            </Typography>
          </Fade>
        }
      </Box>
    </StyledPlayerOverlay>
  );
};

export default PlayerOverlay;