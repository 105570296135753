import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import './PickLesson.css'
import { useTranslation } from 'react-i18next';

export default function PickLesson() {
  const navigate = useNavigate();
  const [lessonId, setLessonId] = useState('');
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle lesson ID submission here
    console.log('Submitted Lesson ID:', lessonId);
    setLessonId('');
    navigate("/watch_lesson/" + lessonId)
  };

  return (
    <>
      <ResponsiveAppBar/>

      <div className="lesson-page-container">
        <h2 className="page-header">{t("pick_lesson.title")}</h2>
        <form className="lesson-form" onSubmit={handleSubmit}>
          <input
            className="input-field"
            type="text"
            placeholder={t("pick_lesson.lesson_id_placeholder")}
            value={lessonId}
            onChange={(e) => setLessonId(e.target.value)}
          />
          <button className="submit-btn" type="submit">{t("pick_lesson.submit")}</button>
        </form>
      </div>
    </>
  );
  
}
