import React, { useEffect } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from "react-router-dom";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import log from 'loglevel';


export default function ClassCard({class_id, name, description, is_admin}) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [classOptions, setClassOptions] = React.useState({});

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(()=> {
    setClassOptions({"settings": t("classes.class_card.settings_option")})
  }, [])

  const handleOpenNavMenu = (event) => {
    log.debug("Opening nav menu")
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (page) => {
    setAnchorElNav(null);
    if (page) {
      log.debug("NavBar: Going to page:", page)
      navigate(class_id + "/" + page);
    } else {
      log.debug("NavBar: empty page")
    }
  };

  return (
    <Card sx={{ maxWidth: 345, height: "30vh", margin: '0 auto'}}>
      <CardHeader
        action={
          is_admin &&
            <>
              <IconButton aria-label="settings" onClick={handleOpenNavMenu}>
                <MoreVertIcon />
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={() => handleCloseNavMenu()}
              >
                {Object.entries(classOptions).map((page_item) => (
                  <MenuItem key={page_item[0]} onClick={() => handleCloseNavMenu(page_item[0])}>
                    <Typography textAlign="center">{page_item[1]}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </>
        }
        title={name}
      />
      <CardContent onClick={() => {navigate(class_id)}}>
        <Typography variant="body2" color="text.primary">
          {description}
        </Typography>
        {is_admin && t("classes.class_card.admin_text")}
      </CardContent>
    </Card>
  );
}
