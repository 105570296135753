import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from "react-router-dom";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';


export default function LessonCard({lesson_id, name, admin, videoLink, isAdmin, classId}) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleOpenNavMenu = (event) => {
    console.log("Opening options menu")
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (option) => {
    setAnchorElNav(null);
    if (option) {
      console.log("Choose option:", option)
    } else {
    }
  };

  const clickOnCard = () => {
    if (isAdmin) {
      navigate(lesson_id)
    } else {
      navigate("/watch_lesson/" + classId + "/" + lesson_id)
    }
  }

  return (
    <Card sx={{ maxWidth: 345, height: "30vh", margin: '0 auto'}} >
      <CardHeader
        action={
          <>
            <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={() => handleCloseNavMenu()}
            >
              {[].map((page_item) => (
                <MenuItem key={page_item} onClick={() => handleCloseNavMenu()}>
                  <Typography textAlign="center">Option name</Typography>
                </MenuItem>
              ))}
            </Menu>
          </>
        }
        title={name}
      />
      <CardContent onClick={clickOnCard}>
        <Typography variant="body2" color="text.primary">
        { isAdmin ?
          t("lessons_page.lesson_card.admin_text")
          :
          t("lessons_page.lesson_card.admin_email") + `${admin}`

        }
        </Typography>
      </CardContent>
    </Card>
  );
}
