import React, { useEffect } from 'react';
import { auth } from '../firebase';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import '../components/Header.css'
import LanguageSwitcher from '../components/LanguageSwitcher.js'
import { useTranslation } from 'react-i18next';


export default function Profile() {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState("");
  const { t } = useTranslation();

  useEffect(() => {
    auth.authStateReady().then(() => {
      // Do whatever you want here ...
      const user = auth.currentUser;
      if (user !== null) {
        setUserEmail(user.email)
        setIsLoggedIn(true)
      } else {
        setIsLoggedIn(false)
      }
    })
  })

  return (
    <>
      <ResponsiveAppBar/>

      <div className="header-container">
        <h1 className="header-title">Profile</h1>
        {isLoggedIn &&
          t("profile_page.email_address") + userEmail
        }
        {!isLoggedIn &&
          t("profile_page.not_logged_in")
        }

        <LanguageSwitcher/>
        
      </div>
    </>
  )
}
