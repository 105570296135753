import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Step from '../components/Step';
import { useTranslation } from 'react-i18next';

// https://www.freecodecamp.org/news/use-firebase-authentication-in-a-react-app/

const Home = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    auth.authStateReady().then(() => {
      // Do whatever you want here ...
      var user = auth.currentUser;
      if (user !== null) {
        setIsLoggedIn(true)
      } else {
        setIsLoggedIn(false)
      }
    })
  })

  const StepStartOnClick = () => {
    if (!isLoggedIn) {
      navigate("/login")
    }
  }
  const StepCreateLearningOnClick = () => {
    navigate("/classes")
  }
  const StepShareOnClick = () => {
    navigate("/classes")
  }


  return (
    <>
      <ResponsiveAppBar/>
      <main className="steps-container">
        <Step number={1} 
              title={t("home_page.step_1_title")} 
              description={t("home_page.step_1_description")}
              onClick={StepStartOnClick}
              isDone={isLoggedIn}/>
        <Step number={2} 
              title={t("home_page.step_2_title")}
              description={t("home_page.step_2_description")}
              onClick={StepCreateLearningOnClick}
              isDone={false}/>
        <Step number={3} 
              title={t("home_page.step_3_title")}
              description={t("home_page.step_3_description")}
              onClick={StepShareOnClick}
              isDone={false}/>

      </main>
    </>
  )
}
 
export default Home