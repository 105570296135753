import * as React from 'react';
import PauseRounded from '@mui/icons-material/PauseRounded';
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';
import { IconButton, Slider, Stack, styled, Typography } from '@mui/material';
import { ReactPlayerProps } from 'react-player';
import { format } from 'date-fns';
import { FullscreenRounded, VolumeDownRounded, VolumeUpRounded } from '@mui/icons-material';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import useWindowSize from '../useWindowSize.js'


import screenfull from 'screenfull';
import { findDOMNode } from 'react-dom';


const StyledPlayerControls = styled('div')`
  position: absolute;
  padding: 10px;
  box-sizing: border-box;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  .video-player__slider {
    width: 100%;
    color: #fff;
    box-sizing: border-box;

    &--seek {
      margin-left: 2px;  // Reduced margin-left
      margin-right: 8px; // Reduced margin-right
    }

    &--sound {
      width: 100px;
    }

    .MuiSlider-track {
      border: none;
    }

    .MuiSlider-thumb {
      background-color: #fff;

      &:before: {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
      }

      &:hover,
      &.Mui-focusVisible,
      &.Mui-active {
        box-shadow: none;
      }
    }
  }

  // Media query for smaller screens
  @media (max-width: 600px) {
    padding: 5px;

    .video-player__slider--seek {
      margin-left: 5px;
      margin-right: 5px;
    }

    .video-player__slider--sound {
      width: 50px;
    }

    .MuiSlider-thumb {
      width: 12px;
      height: 12px;
    }

    // Stack controls vertically on small screens
    .video-player__controls {
      flex-direction: column;
      align-items: flex-start;
    }

    .MuiTypography-body2 {
      font-size: 0.75rem; // Reduce text size for mobile
    }
  }
  @media (max-width: 500px) {
    .video-player__slider--sound {
      width: 40px;
    }
  }
`;


const StyledSlider = styled(Slider)(({ loaded }) => ({
  color: '#fff', // Color of the played portion
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-rail': {
    background: `linear-gradient(
      to right,
      rgba(15, 255, 80) ${loaded}%,
      rgba(255, 255, 255, 0.2) ${loaded}%
    )`,
  },
  // Slider adjustments for smaller screens
  '@media (max-width: 600px)': {
    height: 2, // Reduce slider height for mobile
  },
}));

const PlayerControls = (props) => {
  const { state, dispatch, wrapperRef, playerRef, errorMessage } = props;
  const [isFullscreen, setIsFullScreen] = React.useState(false);
  const size = useWindowSize();    

  const handleSound = (_event, newValue) => {
    dispatch({ type: 'VOLUME', payload: newValue });
  };

  const handleFullscreen = () => {
    screenfull.toggle(findDOMNode(wrapperRef.current));
    setIsFullScreen(!isFullscreen)
  };

  const handleSeek = (_event, newValue) => {
    playerRef.current.seekTo(newValue);
  };

  function decideFontSize() {
    if (size.width > 650) {
      return '2rem'
    } else if (size.width > 550 && size.width <= 650) {
      return '1.5rem'
    } else {
      return '1rem'
    }
  }

  const renderSeekSlider = () => {
    return (
      <StyledSlider
        aria-label="Time"
        className={'video-player__slider video-player__slider--seek'}
        min={0}
        max={state.duration}
        step={0.01}
        value={state.progress.playedSeconds}
        onChange={handleSeek}
        loaded={state.loaded * 100 / state.duration}  // Pass how much did you view up until last time
      />
    );
  };

  const renderPlayButton = () => {
    return (
      <IconButton 
            onClick={() => dispatch({ type: 'TOGGLE_PLAY' })}>
        {state.playing ? (
          <PauseRounded sx={{ fontSize: decideFontSize(), color: 'white' }} />
        ) : (
          <PlayArrowRounded sx={{ fontSize: decideFontSize(), color: 'white' }} />
        )}
      </IconButton>
    );
  };

  const renderSoundSlider = () => {
    return (
      <Stack spacing={2} direction="row" sx={{ mb: 1, px: 1 }} alignItems="center">
        <VolumeDownRounded sx={{ fontSize: decideFontSize(), color: 'white' }} />
        <Slider
          aria-label="Volume"
          className={'video-player__slider video-player__slider--sound'}
          max={1}
          step={0.01}
          value={state.volume}
          onChange={handleSound}
        />
        <VolumeUpRounded sx={{ fontSize: decideFontSize(), color: 'white' }} />
      </Stack>
    );
  };

  const renderDurationText = () => {
    return (
      <Stack spacing={2} direction="row" sx={{ mb: 1, px: 1 }} alignItems="center">
        <Typography variant="body2" color="white">
          {format(new Date(state.progress.playedSeconds * 1000), 'mm:ss')}
          {' / '}
          {format(new Date(state.duration * 1000), 'mm:ss')}
        </Typography>
      </Stack>
    );
  };

  const renderFullscreenButton = () => {
    return (
      <IconButton onClick={handleFullscreen} sx={{ fontSize: decideFontSize(), color: 'white' }}>
        {isFullscreen ? <FullscreenExitIcon/> : <FullscreenRounded/> }
      </IconButton>
    );
  };


  const renderErrorMessage = () => {
    return (
      <div style={{ fontSize: '1rem', color: 'orangered' }}>{errorMessage}</div>
    );
  };

  return (
    <StyledPlayerControls className={'video-player__controls'}>
      <Stack direction="row" alignItems="center">
        {renderSeekSlider()}
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={size.width < 650 ? (size.width < 500 ? 0.1 : 1) : 2}>
          {renderPlayButton()} {renderSoundSlider()} {renderDurationText()} {renderErrorMessage()}
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          {renderFullscreenButton()}
        </Stack>
      </Stack>
    </StyledPlayerControls>
  );
};

export default PlayerControls;