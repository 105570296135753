import React from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import log from 'loglevel';


const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    log.info("Changing language to:", lng)
    i18n.changeLanguage(lng);
  };

  return (
    <div >

      <br/>
      <div style={{gap: 10, flexDirection: 'row', display: "flex", justifyContent: "center"}}>
      <Button variant="contained" onClick={() => changeLanguage('en')}>
        English
      </Button>

      <Button variant="contained" 
              onClick={() => changeLanguage('he')}>
        עברית
      </Button>
      </div>
    </div>
  );
};

export default LanguageSwitcher;