import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../firebase';
import { useNavigate, useParams } from 'react-router-dom';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Step from '../components/Step';
import NonSkippableVideo from '../components/CustomPlayer/NonSkippableVideo.js';
import '../components/videoView.css';
import { useTranslation } from 'react-i18next';


export default function WatchLesson() {
  const navigate = useNavigate();
  const params = useParams();
  const [userEmail, setUserEmail] = useState('');
  const [userToken, setUserToken] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [lessonName, setLessonName] = useState('');
  const [adminName, setAdminName] = useState('');
  const [loadedFromBefore, setLoadedFromBefore] = useState(0);

  const [errorMessage, setErrorMessgae] = useState(null);
  const { t } = useTranslation();


  function getSignedUrlFromServer(user) {
    fetch(process.env.REACT_APP_API_PATH + "/api/class/" + params.class_id + "/lesson/" + params.lesson_id + "/stream", {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'username': user.email,
            'userid': user.accessToken,
        }
    }).then((res) => {
      if (res.status != 200) {
        console.log("Status was", res.status, "Error")
      } else {
        res.json().then((res) => {
          console.log("got video link", res, "from server")
          setVideoLink(res.url)
        })
      }
    }).catch(rejected => {
      console.log(rejected);
    });
  }

  function refreshVideo(user) {
    setTimeout(() => {
      console.log("Refreshing video link");
      getSignedUrlFromServer(user)
      refreshVideo(user) // Set the next timer - infinite recursion - not liking it
    }, 1000 * 60 * 10); // Every 10 minutes
  }


  useEffect(() => {
    auth.authStateReady().then(() => {

      var user = auth.currentUser;
      if (user !== null) {
        setUserEmail(user.email)
        setUserToken(user.accessToken)
        fetch(process.env.REACT_APP_API_PATH + "/api/class/" + params.class_id + "/lesson/" + params.lesson_id, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'username': user.email,
                'userid': user.accessToken,
            }
        }).then((res) => {
          if (res.status != 200) {
            console.log("Status was", res.status, "Error")
            setErrorMessgae(t("watch_lesson.error"))
          } else {
            res.json().then((res) => {
              console.log("got video", res, "from server")
              setVideoLink(res.VideoLink)
              setLessonName(res.LessonName)
              setAdminName(res.Admin)
              let loaded = res.ProgressMap[btoa(user.email)]
              if (loaded) {
                setLoadedFromBefore(loaded)
              }
              if (res.SavedVideo) {
                getSignedUrlFromServer(user)
                refreshVideo(user)
              }
            })
          }
        }).catch(rejected => {
          console.log(rejected);
        });
      } else {
      }
    })
  }, [])


  function handleVideoProgress(playedSeconds) {
    fetch(process.env.REACT_APP_API_PATH + "/api/class/" + params.class_id + "/lesson/" + params.lesson_id + "/users", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'username': userEmail,
            'userid': userToken,
        },
        body: JSON.stringify({
            progress: playedSeconds
        })
    }).then((res) => {
        if (res.status == 500) {
          console.log("Status was", res.status, "Error - Refreshing")
          window.location.reload();
        }
      }
    ).catch(rejected => {
      console.log(rejected);

    });
  }


  return (
    <>
      <ResponsiveAppBar/>
      <br/>
      <Box sx={{ 
          position: 'relative', 
          width: '75%',   // Set width to 80% of the screen
          height: '75vh', // Set height to 80% of the viewport height
          margin: 'auto'  // Center the player horizontally
        }}
      >
        {errorMessage && <b>{errorMessage}</b>}
        {!errorMessage && 
          <NonSkippableVideo 
            className="videoView" 
            url={videoLink} 
            light={
              'https://verivid.app/logo512.png'
            }
            subtitle={adminName}
            title={lessonName}
            handleVideoProgress={handleVideoProgress}
            loadedFromBefore={loadedFromBefore}
          />
        }
      </Box>

    </>
  )
}
 