import * as React from 'react';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material';
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import PlayerControls from './PlayerControls.js';
import PlayerOverlay from './PlayerOverlay.js';
import { INITIAL_STATE, reducer } from './Player.reducer.js';
import useWindowSize from '../useWindowSize.js'
import log from 'loglevel';

import { IconButton, Slider, Stack, Typography } from '@mui/material';
const TIME_DIFF_MARGIN_ERROR = 1.1


// Reference: https://medium.com/@pieecia/create-a-netflix-video-player-with-react-player-typescript-and-styled-components-2142b8003d07

const StyledPlayer = styled('div')`
  position: relative;
  aspect-ratio: 16/9;
  border-radius: 8px;

  video,
  .react-player__preview {
    border-radius: 8px;
  }

  // defined from script, if props light is true then is visible
  .react-player__preview:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.1), transparent);
  }

  &:hover {
    .video-player__controls {
      opacity: 1;
    }
  }

  .video-player__controls {
    opacity: ${({ state }) => (state.light ? '0' : state.playing ? '0' : '1')};
  }
`;

const NonSkippableVideo = (props) => {
  const { url, light, handleVideoProgress, title, subtitle, description, loadedFromBefore } = props;
  const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);
  const [videoErrorMessage, setVideoErrorMessage] = useState(null);
  const playerRef = React.useRef(null);
  const wrapperRef = React.useRef(null);
  const size = useWindowSize();    

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === 'Space') {
        event.preventDefault(); // Prevents page scroll when space is pressed
        dispatch({ type: 'TOGGLE_PLAY' })
      }
      if (event.code === 'ArrowRight') {
        event.preventDefault();
        let target_time = Math.min(state.progress.playedSeconds + 5, state.duration);
        playerRef.current?.seekTo(target_time, 'seconds')
        handleProgressSeconds(target_time)
      }
      if (event.code === 'ArrowLeft') {
        event.preventDefault();
        let target_time = Math.max(state.progress.playedSeconds - 5, 0)
        playerRef.current?.seekTo(target_time, 'seconds')
        handleProgressSeconds(target_time)
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [state.playing, state.progress.playedSeconds]);

  useEffect(() => {
    if (loadedFromBefore) {
      dispatch({ type: 'LOAD', payload: loadedFromBefore })
    }
  }, [loadedFromBefore])

  const handlePreview = () => {
    dispatch({ type: 'LIGHT', payload: false });
  };

  const handlePause = () => {
    dispatch({ type: 'PAUSE' });
  };

  const handlePlay = () => {
    dispatch({ type: 'PLAY' });
  };

  const handleEnded = () => {
    dispatch({ type: 'LIGHT', payload: true });
    playerRef.current?.showPreview();
  };

  const handleProgressSeconds = (playedSeconds) => {
    log.debug("handleProgress", playedSeconds, state.loaded, state.progress.playedSeconds + TIME_DIFF_MARGIN_ERROR)
    if (playedSeconds > state.loaded && (playedSeconds > state.progress.playedSeconds + TIME_DIFF_MARGIN_ERROR )) {
        log.warn("Too fast progress")
        playerRef.current?.seekTo(state.progress.playedSeconds, 'seconds')
        dispatch({ type: 'SEEK', payload: state.progress.playedSeconds });

        setVideoErrorMessage("Error: Video progressed too fast")
    }
    else {
      if (playedSeconds > state.loaded) {
        dispatch({ type: 'LOAD', payload: playedSeconds });
        setVideoErrorMessage(null)
      }
      dispatch({ type: 'SEEK', payload: playedSeconds});

      if (handleVideoProgress) {
        handleVideoProgress(playedSeconds);
      }
    }
  }

  const handleProgress = (progress) => {
    handleProgressSeconds(progress.playedSeconds)
  };

  const handleDuration = (duration) => {
    dispatch({ type: 'DURATION', payload: duration });
  };

  return (
    <StyledPlayer state={state} ref={wrapperRef}>
      <ReactPlayer
        ref={playerRef}
        url={url}
        width="100%"
        height="100%"
        light={light}
        playIcon={
          <PlayArrowRounded
            sx={{
              color: 'white',
              fontSize: '6rem',
            }}
          />
        }
        controls={state.controls}
        loop={state.loop}
        muted={state.muted}
        playing={state.playing}
        playbackRate={state.playbackRate}
        volume={state.volume}
        onPlay={handlePlay}
        onEnded={handleEnded}
        onPause={handlePause}
        onDuration={handleDuration}
        onProgress={handleProgress}
        onClickPreview={handlePreview}
        onClick={() => dispatch({ type: 'TOGGLE_PLAY' })}
      />
      <PlayerOverlay state={state} title={title} subtitle={subtitle} description={description}/>
      {!state.controls && !state.light && (
        <PlayerControls state={state} dispatch={dispatch} playerRef={playerRef} wrapperRef={wrapperRef} errorMessage={videoErrorMessage ? ((size.width < 800) ? "Error" : videoErrorMessage) : ""} />
      )}
    </StyledPlayer>
  );
};

export default NonSkippableVideo;