import React, { useState, useEffect } from 'react';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const ConfirmationPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleGoBack = () => {
    navigate("/classes")
  };

  return (
    <>
      <ResponsiveAppBar/>

        <Button
          className="backButton"
          onClick={handleGoBack}
          startIcon={<ArrowBackIcon />}
          variant="contained"
          color="primary"
        >
          {t("lessons_page.go_to_all_classes")}
        </Button>

        <div className="header-container">
        { t('payment_form.approve') }
        </div>
    </>
  )
}
 
export default ConfirmationPage